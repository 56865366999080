<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.87 486.52"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M106.44,381.34c-69.86-69.8-74.35-188.22-14.09-259.87C122.17,86,158.84,61.34,205.06,53.53c66.56-11.25,125.51,4.37,173.28,51.88l35.48-34.57C336-15.12,174.09-31.54,71.07,70.29c-96,94.86-98,262.89,13.78,358.68,97.53,83.6,245.29,73.5,329-13.35q-17.68-17.13-35.34-34.27C303.76,459.4,174.81,449.66,106.44,381.34Z"/><path d="M374.56,354.56,485.87,243.24,373.63,131l-34,37c15.76,15.74,32.89,32.85,51,50.88H168.68v49.16H390.81L340,320Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconLogOut',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
